import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import './App.css';
import Auth from './Components/Auth';
import { AuthProvider, useAuth } from './context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chat from './Chat';
import { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs';
import { Account } from './Constants/Type';
import { metaApiUrls } from './utils/metaApi';
import axios from 'axios';
import { ref, get, update } from 'firebase/database';
import { database } from './firebase';

// Landing component
const Landing = () => (
  <div>
    <h1>Flynn - Coming Soon</h1>
  </div>
);

// New Login component
const Login = () => (
  <div>
    <h1>Login to Flynn</h1>
    <Auth />
  </div>
);

// Protected route component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const AppContent = () => {
  const { isAuthenticated, facebookAccessToken, user, userState } = useAuth();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string>("");

  const fetchInsights = async (since: Date, until: Date, period: 'day' | 'week' | 'month') => {
    const selectedAccount = accounts.find(
      (account) => account.id === selectedAccountId,
    );
    if (!selectedAccount || !selectedAccount.instagram_business_account) {
      toast.error(
        "No Instagram Business Account was found for the selected account.",
      );
      return;
    }
  
    const instagramBusinessAccountId =
      selectedAccount.instagram_business_account.id;
  
    try {
      const insightsUrl = metaApiUrls.getInsightsUrl({
        igUserId: instagramBusinessAccountId,
        metrics: ['impressions'],
        since,
        until,
        period,
      });
      const response = await axios.get(insightsUrl, {
        params: { access_token: facebookAccessToken },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching insights:", error);
    }
  }

  // update user state
  const updateState = async (updates: { targetPostsPerWeek: number }) => {
    if (!user) {
      return { error: "User not authenticated" };
    }

    console.log("updating user state with:");
    console.log(updates);
    
    try {
      const userRef = ref(database, `users/${user.uid}/state`);
      await update(userRef, updates);
      
      // Get the updated state to return
      const snapshot = await get(userRef);
      const newState = snapshot.exists() ? snapshot.val() : updates;
      
      return { success: true, state: newState };
    } catch (error) {
      console.error("Error updating user state:", error);
      return { error: "Failed to update user state" };
    }
  };

  const fetchState = async () => {
    if (!user) {
      return { error: "User not authenticated" };
    }
    
    try {
      const userRef = ref(database, `users/${user.uid}/state`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        console.log("user state found");
        console.log(snapshot.val());
        return snapshot.val();
      } else {
        // Return default state if none exists
        return {
          targetPostsPerWeek: 0
        };
      }
    } catch (error) {
      console.error("Error fetching user state:", error);
      return { error: "Failed to fetch user state" };
    }
  };

  const functionCallHandler = async (call: RequiredActionFunctionToolCall) => {
    if (call?.function?.name === "get_instagram_insights") {
      const args = JSON.parse(call.function.arguments);
      const since = new Date(args.since);
      const until = new Date(args.until);
      const period = args.timePeriod;

      try {
        const data = await fetchInsights(since, until, period);
        return JSON.stringify(data);
      } catch (error) {
        toast.error("Error fetching insights from Instagram");
        console.error("Error fetching insights:", error);
      }
    } else if (call?.function?.name === "get_user_state") {
      try {
        const state = await fetchState();
        return JSON.stringify(state);
      } catch (error) {
        toast.error("Error fetching user state");
        console.error("Error fetching user state:", error);
        return JSON.stringify({ error: "Failed to fetch user state" });
      }
    } else if (call?.function?.name === "update_user_state") {
      try {
        const args = JSON.parse(call.function.arguments);
        const result = await updateState(args.updates);
        return JSON.stringify(result);
      } catch (error) {
        toast.error("Error updating user state");
        console.error("Error updating user state:", error);
        return JSON.stringify({ error: "Failed to update user state" });
      }
    }

    return JSON.stringify({error: "Unknown function call"});
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/app" /> : <Landing />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/app" /> : <Login />} />
          <Route path="/app" element={
            <ProtectedRoute>
              <Chat userState={userState} functionCallHandler={functionCallHandler} accounts={accounts} setAccounts={setAccounts} selectedAccountId={selectedAccountId} setSelectedAccountId={setSelectedAccountId} />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
};

function AppRouter() {
  return (
    <AuthProvider>
      <AppContent />
      <ToastContainer />
    </AuthProvider>
  );
}

export default AppRouter;
