const FontSizes = {
  small: "12px",
  medium: "14px",
  large: "16px",
};

const FontWeight = {
  regular: 400,
  medium: 500,
};

export interface Account {
  id: string;
  name: string;
  instagram_business_account?: {
    id: string;
  };
}

export interface UserState {
  targetPostsPerWeek: number;
}

export const FB_API_URL = "https://graph.facebook.com/v20.0";

export { FontSizes, FontWeight };